<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <p>
          <i>机构名称</i>
          <el-input v-model="input" maxlength="128" style="width:200px;height:36px" suffix-icon="el-icon-search" placeholder="机构名称/机构编号"></el-input>
          <i class="jianju">产品类型</i>
          <el-select v-model="value" placeholder="全部" style="width:200px;height:36px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <i class="jianju">对账状态</i>
          <el-select v-model="value" placeholder="全部" style="width:200px;height:36px;">
            <el-option label="全部" value=""></el-option>
						<el-option label="平账" value="平账"></el-option>
						<el-option label="异常" value="异常"></el-option>
						<el-option label="挂账" value="挂账"></el-option>
          </el-select>
        </p>
        <p>
          <i>对账日期</i>
          <el-date-picker style="width:200px;height:36px;"v-model="query.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
          </el-date-picker>
          <span style="padding-left:10px;padding-right:10px;">-</span>
          <el-date-picker style="width:200px;height:36px"v-model="query.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
          </el-date-picker>
          <el-button class="searchBt">查询</el-button>
        </p>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>对账日期</p>
            </td>
            <td>
              <p>机构名称</p>
              <p>机构编号</p>
            </td>
            <td>
              <p>交易金额(元)</p>
              <p>退款金额(元)</p>
            </td>
            <td>
              <p>平账交易金额(元)</p>
              <p>平账退款金额(元)</p>
            </td>
            <td>
              <p>交易笔数</p>
              <p>退款笔数</p>
            </td>
            <td>
              <p>异常笔数</p>
              <p>异常金额(元)</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>对账状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>账单日期</p>
            </td>
            <td>
              <p>商户名称</p>
              <p>商户编号</p>
            </td>
            <td>
              <p>储存目录</p>
            </td>
            <td>
              <p>交易笔数</p>
              <p>交易金额</p>
            </td>
            <td>
              <p>退款笔数</p>
              <p>退款金额</p>
            </td>
            <td>
              <p>手续费</p>
              <p>手续费净额</p>
            </td>
            <td>
              <p>结算金额</p>
            </td>
            <td>
              <p>关联渠道编号</p>
            </td>
            <td>
              <p><i class="lianjie">重新生成</i> </p>
              <p><i class="lianjie">下载账单</i></p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[10, 20, 50, 100]"  :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
  </div>
</template>
<script>
	import {
		formatDate
	} from '@/utils/common.js'
  export default {
    data() {
      return {
				query:{
					startTime:formatDate(+new Date(),'yyyy-MM-dd 00:00:00'),
					endTime:formatDate(+new Date(),'yyyy-MM-dd 23:59:59')
				},
        value1: "",
        value2: "",
        crumbs: "批量订单查询",

      }
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
  .active {
    color: rgba(72, 184, 182, 1);
    border-bottom: 3px solid #48B8B6;
  }

  .lianjie {
    color: #008AFF;
		cursor: pointer;
  }

  .tab tr td p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }

  .tab_title {
    background: rgba(244, 245, 246, 1);
    border-top: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 .tab tr {
    padding-left: 20px;
    height: 60px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab {
    border-collapse: collapse;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 {
    width: 100%;
    min-height: 600px;
    margin-top: 20px;
		margin-bottom: 20px;
  }

  .jiaoyi_box p b {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(72, 184, 182, 1);
  }

  .jiaoyi_box p i {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }

  .jiaoyi_box p {
    padding-top: 13px;
    width: 100%;
    text-align: center;
  }

  .jiaoyi {
    width: 100%;
    height: 90px;
    position: relative;
    margin-top: 20px;
  }

  .jiaoyi_box {
    float: left;
    width: 45%;
    height: 90px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .jianju {
    padding-left: 110px;
  }

  .con_from p i {
    padding-right: 12px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .con_from p {
    padding-top: 20px;
    padding-left: 15px;
  }

  .header span {
    display: inline-block;
    height: 100%;
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  .header {
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
    position: relative;
    top: 0;
  }

  #mian {
    padding-left: 20px;
    padding-right: 21px;
    width: 96.5%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .jianju2 {
    display: inline-block;
    width: 51px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
  }

  .searchBt {
    float: right;
    background: #48B8B6;
    border-color: #48B8B6;
    margin-left: 20px;
    width: 90px;
    height: 36px;
    padding: 0;
    color: #FFFFFF;
  }

  .el-pagination {
    margin-bottom: 15px;
  }
</style>
